import React from 'react';
import {graphql, StaticQuery, Link} from 'gatsby';




function DropDownClick(e) {  
	
	if(e.target.className.includes('dropdown-toggle')) {  
		e.preventDefault();
		var x = e.target.parentElement;
		// add item active to surrounding li 
		x.classList.add('item-active');
		// add dropdown active to ul.navigation
		document.querySelector('#nav .navigation').classList.add('dropdown-active');
		//set .show to the specific dropdown menu within the li I just clicked
		//e.target.parentElement.getElementsByClassName('.dropdown-menu').classList.add('show');
		x.querySelector('.dropdown-menu').classList.add('show');
	}
	
}
function BackClick(e) {  
		e.preventDefault();
		var x = e.target.parentElement;
		//e.target.classList.toggle('item-active');
		x.classList.remove('item-active');
		document.querySelector('#nav .navigation').classList.remove('dropdown-active');
		document.querySelector('#nav .navigation .dropdown-menu.show').classList.remove('show');
		document.querySelector('#nav .navigation .item-active').classList.remove('item-active');
		
		//x.querySelector('.dropdown-menu').classList.remove('item-active');
	
	
}
const MainMenu = () => (
		<StaticQuery query={graphql`
		{
		  allWordpressMenusMenusItems( filter: {
		  	name: {
		  		eq: "Main Menu"
		  	}
		  }) {
		    edges {
		      node {
		        items {
		          slug
		          title
		          child_items {
		            title
		            slug
		          }
		        }
		      }
		    }
		  }
		}
		`} render={props => (
			<ul className="navbar-nav navigation">
				{props.allWordpressMenusMenusItems.edges[0].node.items.map((item,index) => (
					
						<li className={item.child_items ? ('nav-item dropdown'): 'nav-item'} key={index}>
						<Link aria-haspopup="true" aria-expanded="false" onClick={DropDownClick} className={item.child_items ? ('nav-link dropdown-toggle'): 'nav-link'} to={item.slug ? `/${item.slug}`:`/`} key={item.title}>{item.title}</Link>
							
							{item.child_items ? (
							<>
							<ul className="dropdown-menu">
							<li className="nav-title d-block"><a onClick={BackClick} href="/">{item.title}</a></li>
							{item.child_items.map((child,index) => (

								<li  key={index}>
									<Link  className="dropdown-link"  to={`/${child.slug}`} key={child.title}>
									{child.title}
									</Link >
								</li>
							 ))}
							 </ul>
							 </>
							 ) : null }
								
						</li>
					
					))}
			</ul>	
		)} />

);

export default MainMenu;