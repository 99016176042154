/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
//import Helmet from "react-helmet"
//import { withPrefix } from "gatsby"
import Head from "./head"
import Header from "./header"
import "./css/bootstrap.min.css"
import "./css/main.css"
import Brand from "../assets/images/img-brand.svg"
/*import FontUrl1 from "../assets/fonts/gothamhtf-medium-webfont.woff2"
import FontUrl2 from "../assets/fonts/gothamhtf-book-webfont.woff2"
import FontUrl3 from "../assets/fonts/gothamhtf-bold-webfont.woff2"
import FontUrl4 from "../assets/fonts/gilmer-outline-webfont.woff2"
import FontUrl5 from "../assets/fonts/gilmer-light-webfont.woff2"
import FontUrl6 from "../assets/fonts/gilmer-regular-webfont.woff2"
import FontUrl7 from "../assets/fonts/gilmer-medium-webfont.woff2"
import FontUrl8 from "../assets/fonts/gilmer-bold-webfont.woff2"
import FontUrl9 from "../assets/fonts/gilmer-heavy-webfont.woff2"
import FontUrl10 from "../assets/fonts/icomoon.woff"*/

const Layout = ({ children }) => {
  

  return (
    
      <div>
      <Head />
      <div>
      <div id="wrapper">
      <noscript>Javascript is required for this page</noscript>
        <Header />
        <main id="main">{children}</main>
        <footer id="footer" className="font-xs text-dark-gray">
          <div className="container">
            <div className="footer-holder border-top boder-color-dark-gray py-8">
              <div className="row">
                <div className="col-md-4 col-lg-5 mb-4">
                  <div className="row justify-content-center">
                    <div className="col-sm-9 col-md-12">
                      <div className="row align-items-center">
                        <div className="col-3 col-lg-2 mb-2 mb-lg-0 text-right text-md-left">
                          <img src={Brand} alt="" />
                        </div>
                        <div className="col-9 col-lg-10">
                          <ul className="list-unstyled list-contacts mb-0">
                            <li><span className="icon-call text-bright-blue"></span> <a className="text-reset" href="tel:61394291318">+61 39429 1318</a></li>
                            <li><span className="icon-map-marker text-bright-blue"></span> <address className="d-inline text-reset font-xs">Level 1/58 Duke Street, Abbotsford VIC 3067</address></li>
                          </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-7">
                <ul className="list-inline social-networks d-flex justify-content-center justify-content-md-end">
                  <li className="list-inline-item"> <a href="http://www.twitter.com/webplaceau" className="twitter text-reset"><span className="sr-only">twitter</span><span className="icon-twitter"></span></a></li>
                  <li className="list-inline-item"> <a href="https://www.facebook.com/webplaceaus" className="facebook text-reset"><span className="sr-only">facebook</span><span className="icon-facebook"></span></a></li>
                  <li className="list-inline-item"> <a href="https://www.instagram.com/design.webplace/" className="instagram text-reset"><span className="sr-only">instagram</span><span className="icon-instagram"></span></a></li>
                  <li className="list-inline-item"> <a href="/lets-talk" className="mail text-reset"><span className="sr-only">mail</span><span className="icon-mail"></span></a></li>
                </ul>
                <ul className="list-inline footer-links d-flex flex-wrap justify-content-center justify-content-md-end text-capitalize">
                  <li><a href="/sitemap">Sitemap</a></li><li>Copyright © Webplace Pty Ltd. 2021</li>
                </ul>
              </div>
            </div>
          </div>  
        </div>
        </footer>
      </div>
        </div>
      </div>
    
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
