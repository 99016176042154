import React from "react"
import MainMenu from "./MainMenu"
import GatsbyLogo from "../assets/svg/logo.inline.svg"
import { Helmet } from 'react-helmet'


function OpenerClick(e) {  
		e.preventDefault();
		document.querySelector('#nav-drop').classList.toggle('show');	
}
const Header = ({ siteTitle }) => (
    
  
    <header id="header">
    <Helmet>
    <script type="application/ld+json">
      {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://www.webplace.com.au",
            "name": "Webplace",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+61394291318",
              "contactType": "Sales"
            }
          }
        `}
    </script>
  </Helmet>
      <div className="container py-3 py-lg-2">
        <div className="row align-items-center">
          <div className="col-lg-2">
            <div className="logo">
              <a href="/">
                <GatsbyLogo />
                <span class="sr-only">Webplace</span>
              </a>
            </div>
          </div>
          <div className="col-lg-10 d-print-none">
            <nav id="nav" className="navbar main-nav navbar-toggleable-lg position-static p-0">
              <button onClick={OpenerClick} className="navbar-toggler navbar-toggler-right d-lg-none" type="button" data-toggle="collapse" data-target="#nav-drop" aria-controls="nav-drop" aria-expanded="false" aria-label="Toggle navigation">
                <span className="nav-opener"><span></span></span>
              </button>
              <div className="collapse navbar-collapse" id="nav-drop">
                <MainMenu />
                  <div className="header-cta d-none d-lg-block px-4 px-lg-0 py-2 py-lg-0"> 
                    <a href="/lets-talk" className="btn btn-light-pink text-blue-2">Contact</a>
                  </div>
                  <div className="search-cta header-cta d-none d-lg-block px-4 pl-lg-8 pr-lg-0 py-2 py-lg-0"> 
                    <a href="/"><span className="sr-only">Search</span><i className="fas fa-search" aria-hidden="true"></i></a>
                  </div>
                </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
)



export default Header
