import React from "react"

const Head = ({ siteTitle }) => (
    

<div>


</div>
)

export default Head
